.icon {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
  height: 6rem;
  width: 6rem;
}

.icon path {
  fill: var(--utilityGray5);
}
