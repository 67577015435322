.icon {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
  height: var(--iconHeight, 2rem);
  width: var(--iconWidth, 2rem);
}

.icon path {
  fill: var(--pencilFillColor, var(--iconFillColor, var(--utilityGray5)));
}
