.button {
  composes: fontBody from '../../../globalStyles/Typography.module.css';
  transition: color 0.2s;
}

.button:hover:not(:disabled) {
  color: var(--neutral-300);
}

.button:not(:disabled) {
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
}

.styled {
  composes: button;
  background-color: var(--buttonBackground, var(--primaryBlue));
  border: 1px solid
    var(--buttonBorder, var(--buttonBackground, var(--primaryBlue)));
  border-radius: var(--buttonBorderRadius, var(--rounded));
  color: var(--buttonColour, var(--white));
  padding: 0.625rem 0.85rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  max-height: min-content;
  text-transform: uppercase;
  font-family: var(--fontFamily);
  text-align: center;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  line-height: normal;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.styled.outlined {
  background-color: transparent;
  border: 1px solid var(--buttonColour, var(--buttonBackground, var(--white)));
  color: var(--buttonBackground, var(--primaryBlue));
}

.styled.secondary {
  background-color: transparent;
  border: none;
  color: var(--buttonColour, var(--white));
  /* text-decoration: underline; */
  /* font-size: 1rem; */
}

.styled.secondary.outlined {
  background-color: transparent;
  border: 1px solid var(--buttonColour, var(--buttonBackground, var(--white)));
  color: var(--buttonColour, var(--white));
  text-decoration: none;
  font-size: 1rem;
}

.styled:not(.secondary):not(.outlined):hover {
  background-color: var(--darkBlue);
}

.unstyled {
  composes: button;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

.unstyled svg, :global(a) {
  transition: transform .2s ease;
}

.unstyled:hover svg, :global(a):hover svg {
  transform: scale(1.15);
}
