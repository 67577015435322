.text {
  color: var(--textColour, var(--neutral-300));
  font-family: inherit;
}

.fontSmall {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 0.75rem;
  line-height: 1.1;
}
.fontSub {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 0.875rem;
  line-height: 1.125;
}
.fontBody {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 1rem;
  line-height: 1.125;
}
.fontLarge {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 1.125rem;
  line-height: 1.66;
  font-weight: 400;
}

.heading1 {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 2em;
  font-family: var(--anybody);
  text-transform: uppercase;
  text-align: center;
}

.heading2 {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 400;
  font-family: var(--anybody);
}

.heading3 {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 400;
  font-family: var(--anybody);
  margin: 0;
  padding: 0;
}
.heading4 {
  composes: text;
  color: var(--textColour, var(--neutral-300));
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-family: var(--fontFamily);
  margin: 0;
  padding: 0;
}
