.footer {
  margin-bottom: 0;
  padding: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.container {
  width: 100%;
  content-visibility: visible;
  transition: height 0.2s ease-out, opacity 0.2s;
  will-change: opacity, height;
  display: flex;
  justify-content: center;
}

.bottomNav {
  padding: 0.5rem 0;
  border-radius: 1.25rem 1.25rem 0 0;
  background-color: var(--utilityGray7);
  display: flex;
  flex-flow: row nowrap;
  justify-content:space-around;
  align-items: center;
  width: 100%;
  max-width: 25.938rem;
  list-style: none;
  --iconHeight: 2rem;
  --iconWidth: 2rem;
  --iconFillColor: var(--primaryWhite);
}

.navItem {
  color: var(--white);
  text-decoration: none;
  font-size: 0.75rem;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  min-height: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 2.25rem;
  position: relative;
}

.profileImage img {
  width: 100%;
  border-radius: 50%;
  padding: 1.25rem 0;
  pointer-events: none;
  
}
.notifyIcon {
  position: absolute;
  top: -0.1875rem;
  right: -0.1875rem;
  border: 0.125rem solid var(--utilityGray6);
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background: var(--redShade1);
  box-sizing: content-box;
}

.navItem:hover,
.navItem.active {
  color: var(--white);
}

.navItem.active .icon,
.navItem.active .profileImage svg {
  fill: var(--primaryBlue);
  stroke: var(--white);
}

.profileImage svg {
  width: 1.875rem;
  height: 1.5rem;
}

.navItem.active>.icon {
  fill: var(--primaryBlue);
  stroke: var(--white)
}

.navItem:hover>.icon {
  fill: currentColor;
  color: var(--primaryBlue);
}

.icon {
  width: 1.875rem;
  height: 1.5rem;
  margin-bottom: 0.313rem;
}

.label {
  font-size: 0.75rem;
}

.navItem.active::before {
  content: "";
  width: 100%;
  height: 0.188rem;
  background-color: var(--primaryBlue);
  position: absolute;
  bottom: -0.5rem;
  border-radius: 0.188rem;
}

@media screen and (max-width: 27.5rem) {
  .bottomNav {
    width: 22rem;
  }
}

@media screen and (max-width: 22.813rem) {
  .bottomNav {
    width: 19rem;
  }
}