:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* prevents unintentional selection on mobile */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* prevent double-tap zoom on mobile */
  touch-action: manipulation;

  /* Colours */
  --primaryBlue: #093efa;
  --primaryWhite: #e7ebf2;
  --primaryBlack: #0c0514;

  --darkBlue: #0a2ca6;
  /* For minimal buttons, links, and inline text links.  */
  --linkBlue: #4e74fd;
  --white: #ffffff;

  --utilityGray1: #b1b8c5;
  --utilityGray2: #8790a0;
  --utilityGray3: #616773;
  --utilityGray4: #494B4F;
  --utilityGray5: #37353d;
  --utilityGray6: #18131e;
  --utilityGray7: #1a1a1a;
  
  --utilityGreen: #2fea9a;

  --secondaryBlue: #4f75fc;
  --secondaryLightBlue: #10e4f8;
  --secondaryOrange: #fa9a09;
  --secondaryYellow: #ffff00;
  --secondaryGreen: #6afd90;
  --secondaryPink: #ee167e;
  --secondaryRed: #e92828;

  --error: #f04040;
  --warning: #f09f1b;
  --success: #2fea9a;

  --greenShade1: #9DE180;
  --redShade1: #F96464;

  --fontBold: 700;
  --fontMedium: 500;
  --fontRegular: 400;

  --anybody: 'Anybody', Impact, Haettenschweiler, 'Arial Narrow Bold',
    sans-serif;
  --fontFamily: 'Roboto', Helvetica, Arial, sans-serif;

  --rounded: 8px;
  --inputBorderRadius: 8px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=fallback');

@font-face {
  font-family: 'Anybody';
  src: url('./assets/fonts/Anybody-CondensedBold.woff2') format('woff2'),
    url('./assets/fonts/Anybody-CondensedBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* * {
  box-sizing: border-box;
  font-family: var(--fontFamily);
  color: var(--primaryWhite);
} */

html {
  font-size: 16px;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
  font-size: 1rem;
  line-height: 1.125;
  color: var(--primaryWhite);
  font-weight: var(--fontRegular);
  background: var(--primaryBlack);
  height: 100%;
}

:golbal(#root) {
  height: 100%;
}

html[class~='mask'] div[id='root'] {
  -webkit-filter: blur(3px);
  -o-filter: blur(3px);
  filter: blur(3px);
  filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius='3');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
html[class~='mask_b'] div[id='root'] {
  -webkit-filter: blur(3px);
  -o-filter: blur(3px);
  filter: blur(3px);
  filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius='3');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

::placeholder {
  color: var(--utilityGray4);
}

input,
textarea {
  -webkit-text-fill-color: var(--primaryWhite);
  -webkit-opacity: 1;
  opacity: 1;
}

::-webkit-input-placeholder {
  -webkit-text-fill-color: var(--utilityGray4);
}

input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--utilityGray6) inset !important;
  -webkit-text-fill-color: var(--primaryWhite) !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}
*::-webkit-scrollbar-corner {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0, 0.4);
  -webkit-border-radius: 7px;
}
