.wrapper {
  height: calc(100svh - 4rem);
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0;
  flex-direction: column;
  background: var(--primaryBlack);
}

.body {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 1.5rem;
}

.bodyInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
}

.bodyInner button {
  width: auto;
}

.bodyInner a {
  color: var(--primaryWhite);
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
}

.bodyInner a:hover {
  color: var(--utilityGray1);
}

.title {
  composes: heading1 from '../../globalStyles/Typography.module.css';
  padding: 0;
  margin: 0;
  color: var(--primaryWhite);
  font-size: 3rem;
  font-weight: 700;
}
.message {
  composes: body from '../../globalStyles/Typography.module.css';
  padding: 0;
  margin: 0;
  color: var(--primaryWhite);
  font-weight: 600;
}

.footer {
  display: flex;
  gap: 1rem;
  margin: auto 0 0 0;
  align-items: center;
  justify-content: center;
}

.footer img {
  max-width: 14rem;
  width: 100%;
}
