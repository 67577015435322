.close {
  height: 2rem;
  margin-left: auto;
  width: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  opacity: 1;
  color: var(--white);
  mask-image: url('../../../assets/images/close.svg');
  mask-repeat: no-repeat;
  background-color: var(--white);
  z-index: 11;
  transition: background-color 0.1s ease-out, transform .2s ease;
}

.close:hover {
  background-color: var(--utilityGray1);
  transform: scale(1.15);
}