.icon {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
  height: var(--iconHeight, 6rem);
  width: var(--iconWidth, 6rem);
}

.icon path {
  fill: var(--iconFillColor, var(--utilityGray5));
}
