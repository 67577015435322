.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: left;
  padding-bottom: 0.3125rem;
}

.value {
  background-color: var(--progressBarColor, var(--secondaryBlue));
}

.path {
  stroke: var(--secondaryLightBlue);
}
