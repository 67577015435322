.app {
  display: flex;
  flex-flow: column;
  height: 100svh;
  width: 100vw;
  position: fixed;
  background: var(--primary-300);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

}

.appWrapper {
  flex: 1;
  display: flex;
  flex-flow: column;
  position: relative;
  z-index: 1000; /* should not overlap side nav */
  height: 100%;
  width: 100%;
  max-width: 66rem;
  margin: auto;
  /* overflow-y: auto; */
}

.pageLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100svh - 5rem);
}

.pageLoader div {
  width: 60rem;
}

.toasterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background-color: var(--primaryWhite);
  border-radius: .25rem;
}

.close {
  position: relative;
  top: auto;
  right: auto;
  background-color: var(--primaryBlack);
}
